.divUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 25px;
  /* padding: 5px; */
}

.divUpload > input {
  display: none;
}

.divUpload svg {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.divUpload > label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.divUpload > label:hover {
  background-color: #d8d8d8;
  padding: 5px;
  font-size: 1.5rem;
  text-align: center;
  border-radius: 50%;
  width: 40px;
  height: 25px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

#gridContainer2 tbody tr:nth-child(1) > td:first-child {
  font-size: 25px;
  padding: 0;
  padding-left: 10px;
}
